<template>
    <div class="new_dialog">
        <van-dialog v-model="config.showDialog" :show-confirm-button="false" width="70%">
            <div class="dialog_div">
                <div class="dialogTip">温馨提示</div>
                <div class="dialogText" :style="{ textAlign: config.textAlign ? config.textAlign : 'left' }">
                    <slot name="text"></slot>
                    {{ config.text }}
                </div>

                <div v-if="config.btnOne" class="btnArr">
                    <van-button class="btn2" color="#0091FF" round type="primary" @click="onConfirm">
                        {{ config.confirmText }}
                    </van-button>
                </div>

                <div v-else class="btnArr">
                    <van-button class="btn1" color="#D8EEFF" round type="primary" @click="onCancel">
                        {{ config.cancelText }}
                    </van-button>
                    <van-button class="btn2" color="#0091FF" round type="primary" @click="onConfirm">
                        {{ config.confirmText }}
                    </van-button>
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
export default {
    props: {
        config: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {

        }
    },
    methods: {
        onCancel() {
            if(this.config.onCancel) {
                this.$emit('cancel')
            }else{
                this.config.showDialog = false
            }
        },
        onConfirm() {
            this.$emit('confirm')
        }
    }

}
</script>
<style scoped>
.van-dialog {
    background: url(../assets/personalIcon/dialogBack.png);
    background-size: cover;
}

.dialog_div {
    min-height: 300px;
    text-align: center;
    padding: 50px 22px 70px 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.dialogTip {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 30px;
    align-items: flex-start;
}

.dialogText {
    margin-bottom: 50px;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 40px;
    text-align: left;
    font-style: normal;
}

.btnArr {
    align-items: flex-end;
}

.btn1,
.btn2 {
    width: 180px;
    height: 66px;
}

.btn1 {
    margin-right: 40px;
}

.btn1>>>.van-button__text {
    color: #0091FF;
    font-size: 26px;
}

.btn2>>>.van-button__text {
    color: #D8EEFF;
    font-size: 26px;
}
</style>